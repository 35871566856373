<template>
  <component :is="layout">
    <div class="row fadeInUp" role="main">
      <div class="col-12">
        <div class="row">
          <div class="col-12 col-md-3">
            <ConfigurationNav />
          </div>
          <div class="col-12 col-md-9">
            <HelpModal class="position-fixed p-4 justify-content-between" v-if="helpModal" @close="openHelp">
              <p>
                create a new filter to display a subset of your orders
              </p>
            </HelpModal>
            <div class="card border-0 shadow-sm">
              <div class="card-header">
                <h4>
                  Filters

                  <router-link
                    v-if="hasPermission('create-filter')"
                    :to="{ name: 'organization.segment.create' }"
                    class="btn btn-sm float-right btn-outline-secondary m-1"
                  >
                    Create a filter
                  </router-link>
                </h4>
              </div>
              <div
                v-if="Object.keys(segments.data).length !== 0"
                class="card-body"
              >
                <data-table
                  :headers="headers()"
                  sort-url="/segment"
                  :method="'get'"
                  :data="segments.data"
                >
                  <template #name="{ item }">
                    {{ item.name }}
                  </template>
                  <template #type="{ item }">
                    {{ item.type }}
                  </template>
                  <template #condition_count="{ item }">
                    {{ item.condition_count }}
                  </template>
                  <template #date="{ item }">
                    <div v-if="item.created_at">
                      {{ item.created_at | formatLocaleDateString }}
                    </div>
                  </template>
                  <template #actions="{ item }">
                    <div>
                      <router-link
                        :to="{
                          name: 'organization.segment.edit',
                          params: { id: item.id },
                        }"
                        class="btn btn-sm btn-outline-secondary m-1"
                      >
                        Edit
                      </router-link>
                      <DeleteButton
                        class="d-inline-block"
                        :uri="`segment/${item.id}`"
                        :name="item.name"
                        :isSmall="true"
                        @deleted="deleteContent(item.id)"
                      />
                    </div>
                  </template>
                </data-table>
                <pagination
                  :data="segments"
                  :limit="3"
                  :show-disabled="true"
                  align="right"
                  @pagination-change-page="getSegments"
                >
                  <span slot="prev-nav" class="text-capitalize"
                    ><i class="fas fa-chevron-left mr-2" /> Prev</span
                  >
                  <span slot="next-nav" class="text-capitalize"
                    >Next<i class="fas fa-chevron-right ml-2"
                  /></span>
                </pagination>
              </div>
              <div v-else class="card-body">
                <p class="text-muted">
                  This is a list of your filters.
                </p>
                <div class="p-4 rounded border-zip text-center">
                  <p class="mb-0">
                    No filters available!
                  </p>
                </div>
              </div>
              <div class="page-help">
                <a @click="openHelp">
                  <QuestionCircle />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </component>
</template>

<script>
import jsonAPI from "@/api";
import Permission from "@/utils/permission";
import Default from "@/layouts/default.vue";
import DataTable from "@/components/DataTable/DataTable.vue";
import ConfigurationNav from "@/components/Nav/ConfigurationNav.vue";
import DeleteButton from "@/components/Form/DeleteButton.vue";
import QuestionCircle from "@/components/Svg/QuestionCircle.vue";
import HelpModal from "@/components/UI/HelpModal.vue";

export default {
  components: {
    DataTable,
    Default,
    ConfigurationNav,
    DeleteButton,
    QuestionCircle,
    HelpModal,
  },
  extends: Permission,
  beforeRouteEnter(to, from, next) {
    let uri = "/segment?page=1";

    if (to.query.sort && to.query.dir) {
      uri += "&sort=" + to.query.sort + "&dir=" + to.query.dir;
    }

    jsonAPI.get(uri).then((response) => {
      next((vm) => {
        vm.segments = response.data;
      });
    });
  },
  data: function() {
    return {
      layout: "Default",
      loading: false,
      segments: {
        data: [],
        meta: {
          total: 0,
        },
        links: {},
      },
      segmentId: "",
      helpModal: false,
    };
  },
  methods: {
    headers() {
      return [
        { title: "Name", key: "name", sortable: true, sortKey: "name" },
        { title: "Type", key: "type", sortable: true, sortKey: "type" },
        {
          title: "Active condition(s)",
          key: "condition_count",
          sortable: true,
          sortKey: "condition_count",
        },
        {
          title: "Created on",
          key: "date",
          sortable: true,
          sortKey: "created_at",
        },
        { title: "Actions", key: "actions" },
      ];
    },
    getSegments: function(page = 1) {
      let uri = "/segment?page=" + page;

      if (this.$route.query.sort && this.$route.query.dir) {
        uri +=
          "&sort=" + this.$route.query.sort + "&dir=" + this.$route.query.dir;
      }

      jsonAPI
        .get(uri)
        .then((response) => {
          this.segments = response.data;
        })
        .catch(() => {
          window.Bus.$emit("flash-message", {
            text: "Error fetching results",
            type: "error",
          });
        });
    },
    deleteContent(id) {
      this.segments.data = this.segments.data.filter((obj) => {
        return obj.id !== id;
      });
    },
    openHelp() {
      this.helpModal = !this.helpModal;
      window.scrollTo(0,0);
    },
  },
};
</script>
