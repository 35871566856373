var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.layout,{tag:"component"},[_c('div',{staticClass:"row fadeInUp",attrs:{"role":"main"}},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-3"},[_c('ConfigurationNav')],1),_c('div',{staticClass:"col-12 col-md-9"},[(_vm.helpModal)?_c('HelpModal',{staticClass:"position-fixed p-4 justify-content-between",on:{"close":_vm.openHelp}},[_c('p',[_vm._v(" create a new filter to display a subset of your orders ")])]):_vm._e(),_c('div',{staticClass:"card border-0 shadow-sm"},[_c('div',{staticClass:"card-header"},[_c('h4',[_vm._v(" Filters "),(_vm.hasPermission('create-filter'))?_c('router-link',{staticClass:"btn btn-sm float-right btn-outline-secondary m-1",attrs:{"to":{ name: 'organization.segment.create' }}},[_vm._v(" Create a filter ")]):_vm._e()],1)]),(Object.keys(_vm.segments.data).length !== 0)?_c('div',{staticClass:"card-body"},[_c('data-table',{attrs:{"headers":_vm.headers(),"sort-url":"/segment","method":'get',"data":_vm.segments.data},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.type)+" ")]}},{key:"condition_count",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.condition_count)+" ")]}},{key:"date",fn:function(ref){
var item = ref.item;
return [(item.created_at)?_c('div',[_vm._v(" "+_vm._s(_vm._f("formatLocaleDateString")(item.created_at))+" ")]):_vm._e()]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('router-link',{staticClass:"btn btn-sm btn-outline-secondary m-1",attrs:{"to":{
                        name: 'organization.segment.edit',
                        params: { id: item.id },
                      }}},[_vm._v(" Edit ")]),_c('DeleteButton',{staticClass:"d-inline-block",attrs:{"uri":("segment/" + (item.id)),"name":item.name,"isSmall":true},on:{"deleted":function($event){return _vm.deleteContent(item.id)}}})],1)]}}],null,false,4021138305)}),_c('pagination',{attrs:{"data":_vm.segments,"limit":3,"show-disabled":true,"align":"right"},on:{"pagination-change-page":_vm.getSegments}},[_c('span',{staticClass:"text-capitalize",attrs:{"slot":"prev-nav"},slot:"prev-nav"},[_c('i',{staticClass:"fas fa-chevron-left mr-2"}),_vm._v(" Prev")]),_c('span',{staticClass:"text-capitalize",attrs:{"slot":"next-nav"},slot:"next-nav"},[_vm._v("Next"),_c('i',{staticClass:"fas fa-chevron-right ml-2"})])])],1):_c('div',{staticClass:"card-body"},[_c('p',{staticClass:"text-muted"},[_vm._v(" This is a list of your filters. ")]),_c('div',{staticClass:"p-4 rounded border-zip text-center"},[_c('p',{staticClass:"mb-0"},[_vm._v(" No filters available! ")])])]),_c('div',{staticClass:"page-help"},[_c('a',{on:{"click":_vm.openHelp}},[_c('QuestionCircle')],1)])])],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }